import React from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

//import Logo from "../../../assets/images/brand-logo.svg";
const Header = () => {
    const navigate = useNavigate();


    const handleLogout =()=>{
     localStorage.clear();
     navigate(`/login`);
    }

    return (

        <nav class="navbar navbar-expand-lg fixed-top py-2 border-bottom">
            <div class="container">
                <Link to="" class="me-5">  HTML POC </Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <Link class="nav-link active" aria-current="page" to="/">Tests</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/client">Candidates</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/client">Library</Link>
                        </li>
                    </ul>
                    <ul class="nav nav-pills">
                        <li class="dropdown">

                            <Dropdown>
                            <Dropdown.Toggle variant="secondry" id="dropdown-basic">
                            J
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            
                            <Dropdown.Item  >Account</Dropdown.Item>
                            <Dropdown.Item  onClick={handleLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                          
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    );
}

export default Header 