// userService.js
import axios from 'axios';
import instance from "../../../wrapper/withAxios";

const API_URL = "https://jsonplaceholder.typicode.com/users";

// Create a new user
export const createTaskApi = async (payloads) => {
  const response =  await instance.post(process.env.REACT_APP_BASE_URL+`client/test/save`, payloads);
  return response.data;
};

// Read all users
export const getList = async (payloads) => {
   
    const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test/get-list`, payloads);
    return response;
       
};

export const getStatusCount = async (payloads) => {
   
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test/get-status-count`, payloads);
  return response;
     
};

// Update a status of task by ID
export const updateStatusApi = async (payloads) => {
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test/update-status`, payloads);
  return response;
};

// Update a user by ID
export const updateUserApi = async (userId, updatedUserData) => {
  console.log("jijijiji",userId);
  const response = await axios.put(`${API_URL}/${userId}`, updatedUserData);
  return response.data;
};

// Delete a user by ID
export const deleteUserApi = async (userId) => {
  const response = await axios.delete(`${API_URL}/${userId}`);
  return response.data;
};
