import React, { useEffect, useState } from "react";
import Head from "../../layout/Header";
import { useDispatch, useSelector } from 'react-redux';
import { getCandidate } from '../../../../features/client/test/candidateSlice';
import { Link } from "react-router-dom";
import TaskHead from "../common/Header";
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams } from 'react-router-dom';

const Test = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { candidates, loading, error, firstLoading, refresh } = useSelector((state) => state.clientTestCandidate);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getList = () => {
    dispatch(getCandidate({ page: currentPage, test_id: id, limit: itemsPerPage, search: search }));
  };


  useEffect(() => {
    getList();
  }, [dispatch, currentPage, search]);

  useEffect(() => {
    if(refresh){
      getList();
    }
  }, [dispatch,refresh]);


  return (
    <main class="app-inner">
      <Head />

      <div class="main-content">
        <div class="page-header border-bottom">
          <div class="container">
            <TaskHead  id={id} />
            <div class="row">
              <div class="col">
                <ul class="nav-secondary">
                  <li><Link to={`/client/test-questions/${id}`} >Questions</Link></li>
                  <li><Link to={`/client/test-library/${id}`} >Library</Link></li>
                  <li><Link to={`/client/test-candidates/${id}`} class="active">Candidates</Link></li>
                  <li><Link to={`/client/test-settings/${id}`}>Settings</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div class="container">
          <div class="d-flex py-4 justify-content-between align-items-center">
            <div class="page-header-left">
              <h1>Candidates</h1>
            </div>
          
          </div>

          <div class="grid-wrapper">
            <table class="table table-custom">
              <thead>
                <tr>
                  <th>Candidate Name</th>                    
                  <th>Status</th>
                  <th>Result</th>
                  <th>Score</th>                      
                  <th></th>
                </tr>
              </thead>

              <tbody>

                {Array.isArray(candidates?.data?.data) && candidates?.data?.data.map((item, index) => (
                  <tr key={item.id}>
                          <td>
                            <span class="table-title" >John Doe</span> <small>{item.email}</small> 
                          </td>
                      <td><i class="bi bi-dash-circle"></i> Pending</td>
                      <td></td>
                      <td></td>
                    <td style={{ textAlign: "right" }}>
                      <Dropdown>
                        <Dropdown.Toggle variant="secondry" id="dropdown-basic">
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>Download Result</Dropdown.Item>
                          <Dropdown.Item>Re-invite</Dropdown.Item>
                          <Dropdown.Item >Extend Deadline</Dropdown.Item>
                          <Dropdown.Item >Archieve</Dropdown.Item>
                          <Dropdown.Item >Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                {loading === false && (!Array.isArray(candidates?.data?.data) || candidates?.data?.data.length < 1) &&
                  <tr><td colSpan="3">No candidates!</td></tr>
                }


              </tbody>

            </table>
            {Array.isArray(candidates?.data?.data) && candidates?.data?.data.length > 0 &&
              <div class="table-footer">
                <h6>{itemsPerPage} of {candidates?.data?.total_count} records</h6>
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={candidates?.data?.total_count}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  prevPageText={'Previous'}
                  nextPageText={'Next'}
                  itemClass="page-item"
                  linkClass="page-link"
                />

              </div>
            }
          </div>
        </div>

      </div>
    </main>
  );
}
export default Test