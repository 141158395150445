import React, { useEffect, useState } from "react";
import Head from "../layout/Header";
import BlankImage from "../../../assets/images/blank.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchTests, fetchTestsStatusCount, addModal, setRedirectFalse, updateStatus } from '../../../features/client/test/testSlice';
import { Link } from "react-router-dom";
import Add from "./Add";
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

const Test = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { tests, statusCount, loading, error, isRedirect, isRedirectId, firstLoading, refresh } = useSelector((state) => state.clientTest);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("Active");
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;


  console.log("statusCount", statusCount);
  const addModalHandle = () => {
    dispatch(addModal());
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleStatusChange = (statusValue) => {
    setStatus(statusValue);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getList = () => {
    dispatch(fetchTests({ page: currentPage, limit: itemsPerPage, status: status, search: search }));
  };

  const doActive = (id) => {
    dispatch(updateStatus({ _id: id, status: "Active" }));
  };
  const doDeactive = (id) => {
    dispatch(updateStatus({ _id: id, status: "Deactive" }));
  };


  useEffect(() => {
    if (isRedirect && isRedirectId) {
      // Redirect to the new page using the isRedirectId
      dispatch(setRedirectFalse());
      navigate(`/client/test-questions/${isRedirectId}`);
    }
  }, [isRedirect, isRedirectId, navigate]); // dependencies: watch changes on isRedirect and isRedirectId

  

  useEffect(() => {
    getList();
  }, [dispatch, currentPage, status, search]);

  useEffect(() => {
    if(refresh){
      getList();
      dispatch(fetchTestsStatusCount());
    }
  }, [refresh]);


  useEffect(() => {
    dispatch(fetchTestsStatusCount());
  }, [dispatch]);

  const activeCount = statusCount?.data?.data[0]?.activeCount ?? 0;
  const deactiveCount = statusCount?.data?.data[0]?.deactiveCount ?? 0;


  return (
    <main class="app-inner">
      <Head />
      <Add />
      <div class="main-content">
    
      { firstLoading === false && (activeCount >0 || deactiveCount>0) &&
        <div class="container">
          <div class="d-flex py-4 justify-content-between align-items-center">
            <div class="page-header-left">
              <h1>Tests</h1>
            </div>
            <div class="page-header-right">
              <div class="btn-group me-3" role="group" aria-label="Basic outlined example">
                <button type="button" onClick={() => handleStatusChange("Active")} className={`btn btn-outline-primary ${status === "Active" ? "active" : ""}`}>Active ({activeCount}) </button>
                <button type="button" onClick={() => handleStatusChange("Deactive")} className={`btn btn-outline-primary ${status === "Deactive" ? "active" : ""}`}>Inactive ({deactiveCount}) </button>
              </div>
              <div class="fr-search me-3">
                <input type="" class="search-input" value={search} onChange={(e) => handleSearchChange(e)} name="" placeholder="Search..." />
              </div>
              <div class="btn btn-primary btn-sm" onClick={addModalHandle}><span class="icon-btn"><i class="bi bi-plus-circle-fill"></i> New Test</span></div>
            </div>
          </div>
          <div class="grid-wrapper">
            <table class="table table-custom">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Questions</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Candidates</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>

                {tests?.data?.data.map((item, index) =>

                  <tr>
                    <td>
                      <Link to={`/client/test-questions/${item._id}`} class="table-title">{item.name}</Link>
                      <small>Last edited {format(new Date(item.updatedAt), "EEE, MMM dd, yyyy, hh:mm a")} by {item.users[0]?.name}</small>

                    </td>
                    <td>{item.questionsCount>0?item.questionsCount:""}</td>
                    <td>{item.totalTimes>0?item.totalTimes+" min":""}</td>
                    {item.status==="Active"?
                    <td><Link to="" class={` text-bg-success badge rounded-pill`}>Test URL {item.status}</Link></td>
                    :
                    <td><div class={`text-bg-warning   badge rounded-pill`}>Test URL {item.status}</div></td>

                    }
                    <td><a href="table-title">{item.candidatesCount>0?item.candidatesCount:""}</a></td>
                    <td >
                      <Dropdown>
                        <Dropdown.Toggle variant="secondry" id="dropdown-basic">
                          Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item ><Link to={"client/test-settings"+item._id}>Settings</Link></Dropdown.Item>
                         {/*<Dropdown.Item >Duplicate</Dropdown.Item>
                          <Dropdown.Item ><Link to={`/client/test-questions/${item._id}`} >Preview</Link></Dropdown.Item>
                          <Dropdown.Item >Invite</Dropdown.Item>*/}
                          {item.status == "Active" ?
                          <Dropdown.Item onClick={()=> doDeactive(item._id)} >Deactivate</Dropdown.Item>
                          :
                          <Dropdown.Item onClick={()=> doActive(item._id)} >Active</Dropdown.Item>
                          }
                          {/*<Dropdown.Item >Delete</Dropdown.Item>*/}
                        </Dropdown.Menu>
                      </Dropdown>

                    </td>
                  </tr>

                )
                }
                {loading === false && tests?.data?.data.length === 0 &&
                  <tr><td colSpan="6">No tests!</td></tr>
                }


              </tbody>

            </table>
            {tests?.data?.data.length > 0 &&
              <div class="table-footer">
                <h6>{itemsPerPage} of {tests?.data?.total_count} records</h6>
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={tests?.data?.total_count}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  prevPageText={'Previous'}
                  nextPageText={'Next'}
                  itemClass="page-item"
                  linkClass="page-link"
                />

              </div>
            }
          </div>

        </div>


        }
        { firstLoading === false && (activeCount === 0 && deactiveCount === 0) &&
          <div class="no-record">
            <div class="no-record-content text-center mt-5">
              <img alt="" src={BlankImage} width="300" />
              <h3>No tests!</h3>
              <p>Get strated by creating a new test.</p>
              <div href="javascript" onClick={addModalHandle} class="btn btn-primary mt-3">
                <span class="icon-btn"><i class="bi bi-plus-circle-fill"></i> Create New Test</span>
              </div>
            </div>
          </div>
        }
      </div>
    </main>
  );
}
export default Test