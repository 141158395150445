
// questionService.js
import instance from "../../../wrapper/withAxios";



// Read all library question
export const getQuestionApi = async (payloads) => {
   
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test-question/get-list`, payloads);
  return response;
     
};


// Read all library question
export const getQuestionCountApi = async (payloads) => {
   
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test/get-single-details`, payloads);
  return response;
     
};


export const removeQuestionApi = async (payloads) => {
   
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test-question/remove-question`, payloads);
  return response;
     
};