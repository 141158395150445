import React, { useEffect, useState } from "react";
import Head from "../../layout/Header";
import { useDispatch, useSelector } from 'react-redux';
import { getQuestion, removeQuestion } from '../../../../features/client/test/questionSlice';
import { Link } from "react-router-dom";
import TaskHead from "../common/Header";
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams } from 'react-router-dom';

const Test = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { questions, loading, error, firstLoading, refresh } = useSelector((state) => state.clientTestQuestion);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;

  const questionType=[{"Multiple Choice Question":"MCQ"}]


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getList = () => {
    dispatch(getQuestion({ page: currentPage, test_id: id, limit: itemsPerPage, search: search }));
  };

  const handleRemove =(questionId)=>{
    dispatch(removeQuestion({ id:questionId, test_id:id }));
   }



  useEffect(() => {
    getList();
  }, [dispatch, currentPage, search]);

  useEffect(() => {
    if(refresh){
      getList();
    }
  }, [dispatch,refresh]);


  return (
    <main class="app-inner">
      <Head />

      <div class="main-content">
        <div class="page-header border-bottom">
          <div class="container">
            <TaskHead  id={id} />
            <div class="row">
              <div class="col">
                <ul class="nav-secondary">
                  <li><Link to={`/client/test-questions/${id}`} class="active">Questions</Link></li>
                  <li><Link to={`/client/test-library/${id}`} >Library</Link></li>
                  <li><Link to={`/client/test-candidates/${id}`}>Candidates</Link></li>
                  <li><Link to={`/client/test-settings/${id}`}>Settings</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div class="container">
          <div class="d-flex py-4 justify-content-between align-items-center">
            <div class="page-header-left">
              <h1>Questions</h1>
            </div>
            <div class="page-header-right">
              <Link to={`/client/test-library/${id}`} class="btn btn-primary btn-sm"><span class="icon-btn"><i class="bi bi-plus-circle-fill"></i> Add Question</span></Link>
            </div>
          </div>

          <div class="grid-wrapper">
            <table class="table table-custom">
              <thead>
                <tr>
                  <th>#</th>
                  <th width="40%">Questions</th>
                  <th>Type</th>
                  <th>Skill</th>
                  <th>Time</th>
                  <th>Difficulty</th>
                  <th>Points</th>
                  <th className="text-end">Actions</th>
                </tr>
              </thead>

              <tbody>

                {Array.isArray(questions?.data?.data) && questions?.data?.data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="pe-3">
                       
                          <strong>
                            {/* Check if questions array exists and has at least one element */}
                            {item?.questions && item?.questions.length > 0 ? item?.questions[0]?.name : 'No Question Name'}
                          </strong>  —  
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod ut labore ...
                       
                      </div>
                    </td>
                    <td>
                      {/* Check if questions array exists and has at least one element */}
                      {item?.questions && item?.questions.length > 0 ? questionType[0][item?.questions[0]?.type] : 'No Type'}
                    </td>
                    <td>
                      <span className="skill-bubbles"><a href="javascript:;">Nodejs</a></span>
                    </td>
                    <td>
                      {/* Check if questions array exists and has at least one element */}
                      <i className="bi bi-clock"></i> {item?.questions && item?.questions.length > 0 ? item?.questions[0]?.expectedTime : 'N/A'} Min
                    </td>
                    <td>
                      {/* Check if questions array exists and has at least one element */}
                      <div className="badge text-bg-danger">
                        {item?.questions && item?.questions.length > 0 ? item?.questions[0]?.difficulty : 'No Difficulty'}
                      </div>
                    </td>
                    <td>
                      {/* Check if questions array exists and has at least one element */}
                      {item?.questions && item?.questions.length > 0 ? item?.questions[0]?.point : '0'}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Dropdown>
                        <Dropdown.Toggle variant="secondry" id="dropdown-basic">
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/*<Dropdown.Item>Move Up</Dropdown.Item>
                          <Dropdown.Item>Move Down</Dropdown.Item>*/}
                          <Dropdown.Item onClick={()=>handleRemove(item?.questions && item?.questions.length > 0 ? item?.questions[0]?._id : '0')} >Remove</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                {loading === false && (!Array.isArray(questions?.data?.data) || questions?.data?.data.length < 1) &&
                  <tr><td colSpan="8">No Question!</td></tr>
                }


              </tbody>

            </table>
            {Array.isArray(questions?.data?.data) && questions?.data?.data.length > 0 &&
              <div class="table-footer">
                <h6>{itemsPerPage} of {questions?.data?.total_count} records</h6>
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={questions?.data?.total_count}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  prevPageText={'Previous'}
                  nextPageText={'Next'}
                  itemClass="page-item"
                  linkClass="page-link"
                />

              </div>
            }
          </div>
        </div>

      </div>
    </main>
  );
}
export default Test